// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Trang chủ',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: 'https://ttu.edu.vn/',
  },
  {
    title: 'Tuyển sinh',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: 'https://tuyensinh.ttu.edu.vn/',
  },
  {
    title: 'Liên hệ',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: 'https://ttu.edu.vn/lien-he/',
  },
];
